import { Button, Modal } from "react-bootstrap";

export default function CannotRechargeWarningModal(props) {
	return 	<Modal show={ props.show }>
				<Modal.Header>
					<h3>
						Recharge Not Available
					</h3>
				</Modal.Header>
				<Modal.Body>
					<p>You can only apply a recharge of a different package speed on or after the day your current service is expiring.</p>
				</Modal.Body>
				<Modal.Footer>
					<Button onClick={ e => props.onOKClicked() }>OK</Button>
				</Modal.Footer>
			</Modal>
}