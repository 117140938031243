import { useState } from "react";

export default function GreenButton(props) {
	const [hovering, setHovering] = useState(false);

	var height = "105px";

	if(props.sm) {
		height = "92px"
	} else if(props.xs) {
		height = "72px"
	}

	var style = {
		width: "85%",
		height: height,
		borderRadius: "32px",
		cursor: "pointer",
		border: "2px solid #009845",
		color: "#009845",
		backgroundColor: "white"
	 };

	if(props.solid) {
		if(hovering) {
			style.backgroundColor = "white";
			style.color = "#009845";
		} else {
			style.backgroundColor = "#009845";
			style.color = "white";
		}
	}

	return 	<button onMouseEnter={e => setHovering(true)}  onMouseLeave={e => setHovering(false)}
				onClick={props.onClick} className="d-flex text-center mb-3 fs-2 justify-content-center align-items-center" style={ style }>
				{props.children}
			</button>
}