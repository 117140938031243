import { Col, Row } from "react-bootstrap";

export default function StepContainer(props) {
	return 	<Row className="p-3 d-flex align-items-center">
				<Col className="border p-3 p-sm-5 bg-white">
					<Row className="mb-5 text-center">
						<Col>
							<h4>{props.title}</h4>
						</Col>
					</Row>
					{ props.children }
				</Col>
			</Row>
}