import React, { useState } from "react";
import { Col, Form, Row, Button } from "react-bootstrap";
import { RegistrationSteps } from "../Registration";
import StepContainer from "./StepContainer";

export default function Welcome(props) {
	const [consentPOPIA, setPOPIAConsent] = useState(false);
	const [showingConsentError, showConsentError] = useState(false);

	function handleContinueClicked() {
		if(consentPOPIA === false) {
			showConsentError(true);
		} else {
			showConsentError(false);
			props.onContinue(null, RegistrationSteps.Welcome.Step);
		}
	}

	return	<StepContainer title="Welcome to Zing Fibre!">
				<React.Fragment>
					<Row className="mb-5">
						<Col>
							<h5>Please go through the following questions to sign up for your account and enable internet services within your home.</h5>
							<h5 className="mt-3">Disclaimer:</h5>
							<h6 className="mt-1" style={{ fontSize: "1rem" }}>Zing Fibre will provide the customer with a free to use Customer Premises Equipment (CPE) which remains the property of Zing Fibre into perpetuity. Zing Fibre retains the right to repossess the CPE at any point where it deems the customer is not making full use of the CPE. Following a period of 3 months without any activity in the customer's account, Zing Fibre will consider that the client is no longer making full use of the free to use CPE and will act on its right to repossess the CPE. Zing Fibre retains the right to hold the customer liable should the CPE be damaged, sold, transferred or otherwise disposed of.</h6>
						</Col>
					</Row>
					<Row className="mb-5">
						<Col>
							<Row className="text-center">
								<Col>
									<Form>
										<div className="form-check">
											<input style={{ marginTop: "0.4rem" }} checked={ consentPOPIA } onChange={ (e) => setPOPIAConsent(e.target.checked)} type="checkbox" className="form-check-input" />
											<label className="form-check-label">I consent to the collection of my information in accordance with the POPIA act.</label>
										</div>
									</Form>
								</Col>
							</Row>
							<Row className="text-center">
								<Col className="d-flex justify-content-center">
									<h6 className="text-danger">{ (showingConsentError ? "Consent to collect information is required" : " ")}</h6>
								</Col>
							</Row>
						</Col>
					</Row>

					<Row className="text-center">
						<Col xs={12} md={6} lg={6} xl={4} xxl={4} className="offset-xs-0 offset-md-3 offset-xl-4">
							<Button className="w-100" onClick={handleContinueClicked}>Continue</Button>
						</Col>
					</Row>
				</React.Fragment>
			</StepContainer>
}