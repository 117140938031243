import { HashRouter } from "react-router-dom";
import BottomWave from "./Core/BottomWave";
import RightDecorations from "./Core/RightDecorations";
import Main from "./Main";
import Wave from "../Images/Wave.svg"

export default function App() {
  	return	<HashRouter>
		  		<div className="h-100 d-flex flex-column overflow-auto">
		  			<Main></Main>
				</div>
    		</HashRouter>
}