import { Field } from "formik";
import React from "react";
import { Col, Form, Row } from "react-bootstrap";

export default function Details(props) {
	return 	<React.Fragment>
				<Form.Group as={Row} className="mb-3">
					<Form.Label column sm={4}>First Name</Form.Label>
					<Col sm={8}>
						<Field readOnly={props.disabled} isInvalid={props.errors.FirstName && props.touched.FirstName } as={Form.Control} name="FirstName"></Field>
						<small className="text-danger">{ props.touched.FirstName && props.errors.FirstName }</small>
					</Col>
				</Form.Group>
				<Form.Group as={Row} className="mb-3">
					<Form.Label column sm={4}>Surname</Form.Label>
					<Col sm={8}>
						<Field readOnly={props.disabled} isInvalid={props.errors.Surname && props.touched.Surname } as={Form.Control} name="Surname"></Field>
						<small className="text-danger">{ props.touched.Surname && props.errors.Surname }</small>
					</Col>
				</Form.Group>
			</React.Fragment>
}