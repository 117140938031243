import React, { useState } from "react";
import { Button, Col,  Container,  Row, } from "react-bootstrap";
import Logo from "./Core/Logo";
import {useParams } from "react-router-dom";
import FileUploadBlock from "./Registration/Steps/FileUploadBlock";
import { useSessionStore } from "../Stores/SessionStore";
import StepContainer from "./Registration/Steps/StepContainer.jsx";
import Outro from "./Registration/Steps/Outro";

export default function Resubmit() {
	const[submitted,setSubmitted] = useState(false);
	const[submitting,setSubmitting] = useState(false);
	const [id, setId] = useState((null))
	const store = useSessionStore();
	const [showingIDError, showIDError] = useState(false);
	const params = useParams();

	async function handleContinueClicked() {
		var customerRegistration = {
		
			IDDocument: id,
		}
		setSubmitting(true);
		if(!id) {
			showIDError(true);
			return;
		} else {
			showIDError(false);
		}
		var result = await store.CustomersService.ResubmitCustomerDocument(params.customerId,customerRegistration);

			if(result && result.Success) {
				setSubmitted(true);
			} else {
				if(result) {
					setSubmitting(false);
					setSubmitted(false);
				}

				
			}


		
	}



	function handleIDUploaded(file, fileName) {
		setId({ Content: file, FileName: fileName });
	}

	

	return	<Container fluid>
				<Row className="d-flex align-items-center my-4 mb-5" style={{paddingLeft:50, paddingRight:150, paddingTop:40}}>
					<Col xs={12} md={8} lg={7}>
						<Logo></Logo>
					</Col>
				</Row>
			<StepContainer title="Upload documentation for RICA purposes:">
				{
					!submitted &&
					<React.Fragment>
				
					<Row className="mb-5" style={{paddingLeft:170, paddingRight:170}}>
						<Col>
							<h4 className="mb-4">We require the following documentation to be Re-uploaded in order to adhere to the RICA Act 70 of 2002.</h4>
						</Col>
					</Row>
					<Row  className="text-center d-none d-md-flex" style={{paddingLeft:170, paddingRight:170}}>
						<Col xs={12} md={12} xl={12} className="offset-xl-1">

						</Col>
			
					</Row>
					<Row className="text-center" style={{paddingLeft:170, paddingRight:170}}>
						<Col xs={12} md={12} xl={12} className=" mb-3">
							<FileUploadBlock filename={ (id ? id.FileName : null ) } file={ (id ? id.Content : null) } onFileChanged={ handleIDUploaded } name="IdBook" description="ID Book or Card"></FileUploadBlock>
						</Col>
						
					</Row>
					<Row className="text-center mb-5" style={{paddingLeft:170, paddingRight:170}}>
						<Col className="d-flex justify-content-center">
							<h6 className="text-danger">{ (showingIDError ? "A file containing an image of your ID Document is required." : " ")}</h6>
							
						</Col>
					</Row>
					<Row className="text-center">
						<Col xs={12} lg={6} className="offset-xs-0 offset-lg-3" style={{paddingLeft:170, paddingRight:170}}>
							<Button className="w-100" disabled={submitting} onClick={ handleContinueClicked }>Submit</Button>
						</Col>
					</Row>
				</React.Fragment>}
				{submitted &&
				<Outro preference={"Email"}></Outro>
				}
			</StepContainer>
			</Container>
}