import { DateTime } from "luxon";
import React from "react";
import { Card, Col, Row } from "react-bootstrap";
import { useSessionStore } from "../../../../Stores/SessionStore";

export default function ServiceDetails(props) {
	const store = useSessionStore();

	return 	<Card className="h-100">
				<Card.Header>
					<h4>Service Details</h4>
				</Card.Header>
				<Card.Body>
					{
						store.CustomerHasActiveService() === false &&
						<span>No Current Service</span>
					}
					{
						props.details && store.CustomerHasActiveService() &&
						<React.Fragment>
							<Row>
								<Col>Active Package:</Col>
								<Col>
									{
										props.rechargeHistory && props.rechargeHistory[0] &&
										<span>{props.rechargeHistory[0].Package}</span>
									}
									{
										(!props.rechargeHistory || !props.rechargeHistory[0]) &&
										<span>{props.details.ServiceName}</span>
									}
								</Col>
							</Row>
							<Row>
								<Col>Expiry Date:</Col>
								<Col>{ DateTime.fromISO(props.details.ExpiryDate).toFormat("dd/MM/yyyy") }</Col>
							</Row>
							<Row>
								<Col>Current Usage:</Col>
								<Col>{ props.details.CurrentUsage }</Col>
							</Row>
							<Row>
								<Col>Current IP:</Col>
								<Col>{ props.details.IPAddress }</Col>
							</Row>
							{/*
								<Row>
									<Col>Account Type:</Col>
									<Col>{ props.details.AccountType }</Col>
								</Row>
							*/}

						</React.Fragment>
					}

				</Card.Body>
			</Card>
}