import React, { useRef } from "react"
import { useState } from "react"
import { Button, Col, Form, Row } from "react-bootstrap"
import { useSessionStore } from "../../../../Stores/SessionStore";
import md5 from 'crypto-js/md5';
import CashPaymentModal from "./CashPaymentModal";

export default function Confirmation(props) {
	const store = useSessionStore();
	const formRef = useRef(null);

	const [isNotSubscription, setIsNotSubscription] = useState(false);
	const [showCashPaymentModal, setShowCashModalPayment] = useState(false);
	const [customerDetails, setCustomerDetails] = useState(null);
	const [creatingPaymentRequest, setCreatingPaymentRequest] = useState(false);

	function getHashValue(value) {
		return encodeURIComponent(value.trim()).replace(/%20/g, "+");
	}
	function removeInvalidCharacters(value) {
		return value.replace("'", "");
	}

	function getHash() {
		var hashInput = `merchant_id=${getHashValue(store.Config.payfastMerchantId)}&` +
						`merchant_key=${getHashValue(store.Config.payfastMerchantKey)}&` +
						`return_url=${getHashValue(store.Config.payfastSuccessUrl)}&` +
						`cancel_url=${getHashValue(store.Config.payfastCancelUrl)}&` +
						`notify_url=${getHashValue(store.Config.payfastNotifyUrl)}&` +
						`name_first=${getHashValue(removeInvalidCharacters(store.Session.Customer.FirstName))}&` +
						`name_last=${getHashValue(removeInvalidCharacters(store.Session.Customer.Surname))}&` +
						`email_address=${getHashValue(store.Session.Customer.Email)}&` +
						`amount=${getHashValue(props.product.Price.toFixed(2))}&` +
						`item_name=${getHashValue(`${ props.category } ${props.product.Name } Access`)}&` +
						`custom_str1=${props.product.Id}&` +
						`custom_str2=${store.Session.Customer.Id}&`;

		if(!isNotSubscription && props.product.Period === 31) {
			hashInput += `subscription_type=1&`;
			hashInput += `recurring_amount=${getHashValue(props.product.Price.toFixed(2))}&`;
			hashInput += `frequency=3&`;
			hashInput += `cycles=0&`;
		}

		hashInput += `passphrase=${getHashValue(store.Config.payfastPassphrase)}`;

		var result = md5(hashInput);

		return result;
	}

	async function handleGetUserDetails() {
		let results = await store.CustomersService.GetCustomerDetails();

		if(results && results.Success) {
			setCustomerDetails(results.Data.Customer);
			setShowCashModalPayment(true);
		}
	}

	async function onOnlineSubmit(e) {
		e.preventDefault();
		setCreatingPaymentRequest(true);
		
		try	{
			await store.PaymentRequestsService.CreatePaymentRequest(props.product.Id);
			setCreatingPaymentRequest(false);
		} catch(exception) {

		}

		formRef.current.submit();
	}

	return 	<React.Fragment>
				<Row>
					<Col className="text-center">
						{
							props.product.Period === 31 &&
							<p>Your subscription will renew every month until you cancel it. Click the tick box below if you wish to purchase a once off monthly package using other payment options.</p>
						}
						{
							props.product.Period !== 31 &&
							<p>Please verify you would like to add the following voucher:</p>
						}
						
						<p>{ props.category } {props.product.Name } Access (R {props.product.Price.toFixed(0)})</p>
					</Col>
				</Row>
				{
					props.product.Period === 31 &&
					<Row>
						<Col className="text-center">
								<div className="form-check">
									<input style={{ marginTop: "0.4rem" }} checked={ isNotSubscription } onChange={ (e) => setIsNotSubscription(e.target.checked)} type="checkbox" className="form-check-input" />
									<label className="form-check-label" style={{color: 'red', fontWeight: 'bold'}}>Tick this box to opt out of a monthly recurring subscription.</label>
								</div>
						</Col>
					</Row>
				}
				{
					props.product.Period === 1 &&
					<Row>
						<Col className="text-center">
								<div className="form-check">
									<label className="form-check-label" style={{color: 'red', fontWeight: 'bold'}}>Please Note: THIS PACKAGE EXPIRES THE SAME DAY OF PURCHASE.</label>
								</div>
						</Col>
					</Row>
				}

				<Form ref={ formRef } action={ `${store.Config.payfastUrl}process` } method="post" onSubmit={ onOnlineSubmit }>
					<Row className="pt-4">
						<input type="hidden" name="merchant_id" value={ store.Config.payfastMerchantId } />
						<input type="hidden" name="merchant_key" value={ store.Config.payfastMerchantKey } />
						<input type="hidden" name="return_url" value={ store.Config.payfastSuccessUrl } />
						<input type="hidden" name="cancel_url" value={ store.Config.payfastCancelUrl } />
						<input type="hidden" name="notify_url" value={ store.Config.payfastNotifyUrl } />
						<input type="hidden" name="name_first" value={ removeInvalidCharacters(store.Session.Customer.FirstName) }/>
						<input type="hidden" name="name_last" value={ removeInvalidCharacters(store.Session.Customer.Surname) }/>
						<input type="hidden" name="email_address" value={ store.Session.Customer.Email }/>
						<input type="hidden" name="amount" value={ props.product.Price.toFixed(2) } />
						<input type="hidden" name="item_name" value={ `${ props.category } ${props.product.Name } Access`} />
						<input type="hidden" name="custom_str1" value={ props.product.Id } />
						<input type="hidden" name="custom_str2" value={ store.Session.Customer.Id } />

						{
							(!isNotSubscription && props.product.Period === 31) &&
							<React.Fragment>
								<input type="hidden" name="subscription_type" value="1"/>
								<input type="hidden" name="recurring_amount" value={ props.product.Price.toFixed(2) }/>
								<input type="hidden" name="frequency" value="3"/>
								<input type="hidden" name="cycles" value="0"/>
							</React.Fragment>
						}

						<input type="hidden" name="signature" value={ getHash() } />
						<Col xs={12} md={6} className="offset-0">
							<Button disabled={ creatingPaymentRequest } type="submit" className="w-100 mb-3" style={{backgroundImage: "linear-gradient(130deg, #009845 0%, #FFE200 100%)"}}>{ creatingPaymentRequest ? "Online Payment..." : "Online Payment" }</Button>
						</Col>
						<Col xs={12} md={6}>
							<Button type="button" className="w-100" onClick={ () => handleGetUserDetails() } style={{backgroundImage: ( !isNotSubscription && props.product.Period === 31 ? "linear-gradient(90deg, rgba(92,92,92,1) 20%, rgba(204,204,204,1) 100%, rgba(0,212,255,1) 100%)" : "linear-gradient(130deg, #009845 0%, #FFE200 100%)"), cursor: ( !isNotSubscription && props.product.Period === 31 ? "not-allowed" : "pointer")}} disabled={ !isNotSubscription && props.product.Period === 31 }>In-store Payment</Button>
						</Col>
					</Row>
					<Row>
						<Col className="text-center">
							<Button type="button" className="w-100 col-md-6 p-n1 mt-3" onClick={ e => props.onSubmit(false) } style={{backgroundImage: "linear-gradient(150deg, #FFEF00 0%, #FF7800 100%)"}}>Cancel</Button>
						</Col>
					</Row>
				</Form>
				{
					customerDetails &&
					<CashPaymentModal 
						show={ showCashPaymentModal } 
						onClose={ setShowCashModalPayment } 
						cancelTransaction={ props.onSubmit } 
						contactPreference={ customerDetails.EmailPreference }
						contactDetails={ customerDetails.EmailPreference === "Email" ? customerDetails.Email : customerDetails.CellphoneNumber } 
						productDetails={ props.product } 
					/>
				}
			</React.Fragment>
}