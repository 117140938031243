import { Formik, Form } from "formik";
import React, { useState } from "react";
import { Col, Row, Button } from "react-bootstrap";
import * as yup from 'yup';
import { validate } from 'luhn'
import { RegistrationSteps } from "../Registration";
import StepContainer from "./StepContainer";
import Address from "../../Core/Customer/Address";
import Communication from "../../Core/Customer/Communication";
import Details from "../../Core/Customer/Details";
import IDNumberInput from "../../Core/IDNumberInput";

export default function CustomerDetails(props) {
	const [customer, setcustomer] = useState(props.customer || {
			FirstName: "",
			Surname: "",
			Email: "",
			CellphoneNumber: "",
			LanguagePreference: null,
			EmailPreference: null,
			IDNumber: "",
			IDNumberPreference: "ID"
	});

	function handleFormSubmitClicked(data) {
		props.onContinue(data, RegistrationSteps.CustomerDetails.Step);
	}
	
	const validationSchema =
        yup.object({
            FirstName: yup.string().required("First Name is required."),
            Surname: yup.string().required("Surname is required."),
			EmailPreference: yup.mixed().required("Communication Preference is required."),

			Email: yup
			.string()
			.when("EmailPreference", {
			  is: "Email",
			  then: yup.string().required("Email is required.").email("Invalid Email Format"),
			  otherwise: yup.string().email("Invalid Email Format"),
			}),
			
            CellphoneNumber: yup.string().when("EmailPreference", {
				is: "Phone",
				then: yup.string()
						 .required("Cellphone Number is required.")})
						 .matches(/^\d{9,10}$/, "Phone number is not valid."),
            LanguagePreference: yup.mixed().required("Language Preference is required"),
			IDNumber: yup.string().when("IDNumberPreference", {
							is: "ID",
							then: yup.string()
							.required("ID Number is required")
							.min(13, "Must contain 13 characters")
							.matches(/^\d+$/, "Invalid ID Number")
							.test({ message: "Invalid ID Number", test: (value) => validate(value) }),
							otherwise: yup.string().required("Passport Number is required")
						})
					 }
        ).required();

	return	<StepContainer title="Please enter the following Customer Information in order to setup your account:">
				<Row>
					<Col>
						<Formik validationSchema={ validationSchema } initialValues={customer} onSubmit={ handleFormSubmitClicked }>
						{({ errors, touched }) => (
							<Form>
								<Details touched={touched} errors={errors}></Details>
								<Address address={props.address}></Address>
								<Communication touched={touched} errors={errors}></Communication>

								<IDNumberInput preferenceField={ "IDNumberPreference" } idField={ "IDNumber" } />

								<Row className="mt-5">
									<Col xs={12} lg={6} className="offset-xs-0 offset-lg-3">
										<Button className="w-100" type="submit">Submit</Button>
									</Col>
								</Row>
							</Form>
								)}
						</Formik>
					</Col>
				</Row>
			</StepContainer>
}