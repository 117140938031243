import { DateTime } from "luxon";
import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { useSessionStore } from "../../../../Stores/SessionStore";

export default function SubscriptionPaymentFailedModal(props) {
	const store = useSessionStore();
	const [cancelling, setCancelling] = useState(false);
	const [cancelled, setCancelled] = useState(false);
	const [cancelError, setCancelError] = useState(false);

	useEffect(() => {
		if(props.show) {
			setCancelling(false);
			setCancelled(false);
			setCancelError(false);
		}
	}, [props.show])

	async function handleOkClicked() {

		setCancelling(true);

		var result = await store.CustomersService.CancelSubscription(props.details.Customer.Id);

		if(result && result.Success) {
			setCancelling(false);
			setCancelled(true);
            props.onClose(true);

		} else {
			setCancelling(false);
			setCancelError(true);
		}
	}

	return 	<Modal show={ props.show }>
				<Modal.Header>
					<h3>
						Subscription Payment Failed
					</h3>
				</Modal.Header>
				<Modal.Body>
                    {
                        !cancelled && !cancelError &&
                        <>
                            Your subscription payment has failed. Please click Ok to purchase a new package.
                        </>
                    }
                    {
                        cancelError &&
                        <>
                            <p>An error ocurred attempting to cancel your service.</p>
                            <p>Please contact Zing Fibre Support.</p>
                        </>
                    }
                </Modal.Body>
				<Modal.Footer>					
                    {
                        !cancelled && !cancelError &&
                        <Button disabled={ cancelling } onClick={ handleOkClicked }>Ok</Button>
                    }
                    {
                        cancelError &&
                        <Button disabled={ cancelling } onClick={ e => props.onClose(false) }>Close</Button>
                    }
				</Modal.Footer>
			</Modal>
}