import { observer } from "mobx-react-lite";
import { useState } from "react";
import { Form as FormikForm, Formik, Field } from "formik";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useSessionStore } from "../../Stores/SessionStore";
import * as yup from 'yup';
import { UsernameInput, getUsernameValidation } from "../Core/UsernameInput";

const LoginModal = observer(function LoginModal(props) {
	const [showLoginError, setShowLoginError] = useState(false);

	const store = useSessionStore();
	let navigate = useNavigate();

	async function handleLoginClicked(values) {
		var result = await store.Login(values.Username, values.Password, values.Preference);

		if((result && result.Success === false) || !result) {
			setShowLoginError(true);
		} else {
			navigate("/Portal/Home");
		}
	}

	function handleKeypress(key, submitForm) {
		if(key === "Enter") {
			submitForm();
		}
	}

	const validationSchema = yup.object({
		Username: getUsernameValidation("Preference"),
		Password: yup.string().required("Password is required.")
	}).required();

	return 	<Modal  size="lg" show={ props.show }>
				<Formik validationSchema={validationSchema} initialValues={{ Username: "", Password: "", Preference: "Phone" }} onSubmit={handleLoginClicked}>
				{({ errors, touched, submitForm }) => (
					<FormikForm>
						<Modal.Header>
							<h3>
								Login
							</h3>
						</Modal.Header>
						<Modal.Body>
							<Form>
								<UsernameInput busy={ store.LoggingIn } preferenceField="Preference" usernameField="Username" />
								<Form.Group as={ Row } className="mt-3">
									<Form.Label column>Password</Form.Label>
									<Col sm={8}>
										<Field 
											type="password" 
											as={Form.Control} 
											name="Password" 
											disabled={ store.LoggingIn } 
											isInvalid={errors.Password && touched.Password}
											onKeyPress={ e => handleKeypress(e.code, submitForm)}/>
										<small className="text-danger">{touched.Password && errors.Password}</small>
									</Col>
								</Form.Group>
							</Form>
							{
								showLoginError &&
								<small className="text-danger">Invalid Username or Password</small>
							}
							<div className="d-flex justify-content-end mt-3">
								<small onClick={ props.OnForgotPasswordClicked } className="text-primary" style={{ cursor: "pointer" }}>I Forgot My Password</small>
							</div>
						</Modal.Body>
						<Modal.Footer>
							<Button disabled={ store.LoggingIn } type="submit">Login</Button>
							<Button disabled={ store.LoggingIn } onClick={ props.onClose }>Cancel</Button>
						</Modal.Footer>
					</FormikForm>
				)}
				</Formik>
			</Modal>
});

export default LoginModal;