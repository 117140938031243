import { Button, Modal } from "react-bootstrap";

export default function ComingSoonModal(props) {
	return 	<Modal show={ props.show }>
				<Modal.Header>
					<h3>
						Coming Soon
					</h3>
				</Modal.Header>
				<Modal.Body>
					<p>This feature is not available yet.</p>
				</Modal.Body>
				<Modal.Footer>
					<Button onClick={ e => props.onOKClicked() }>OK</Button>
				</Modal.Footer>
			</Modal>
}