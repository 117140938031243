import React from "react";
import { Col, Row, Button } from "react-bootstrap";
import StepContainer from "./StepContainer";


export default function Outro(props) {

	console.log(props)
	return	<StepContainer title="Registration is Pending Document Review">
				<React.Fragment>
					<Row className="mb-5">
						<Col>
							<h4 className="mb-4">Your documents are currently being reviewed. You will receive an {props.preference ==="Email" ? "email" : "SMS"} within 24 hours confirming your registration with instructions on how to log in and top up your account.</h4>
						</Col>
					</Row>
					<Row className="text-center">
						<Col xs={12} lg={6} className="offset-xs-0 offset-lg-3">
							<Button className="w-100" onClick={  e => window.location.hash = "/" }>Go back Home</Button>
						</Col>
					</Row>
				</React.Fragment>
			</StepContainer>
}