import APIBase from "./APIBase";

/**
 * @typedef PaymentRequestsService
 * @type {PaymentRequestsService}
 */
export class PaymentRequestsService extends APIBase {
	/**
	 * @param {string} apiUrl
	 * @param { import("../Stores/SessionStore").SessionStore } sessionStore
	 **/
	constructor(apiUrl, sessionStore) {
		super(apiUrl + "PaymentRequests", sessionStore);
	}

	/**
	 * @returns { import("./APIBase").RequestResult<boolean> }
	 */
	async CreatePaymentRequest(productId) {
		return this.Post(`CreatePaymentRequest?productId=${productId}`);
	}
}