import { useEffect } from "react";
import { useState } from "react";
import { Container } from "react-bootstrap";
import { Route, Routes, useNavigate } from "react-router-dom";
import AddressSelection from "./Steps/AddressSelection";
import ConfirmCustomerDetails from "./Steps/ConfirmCustomerDetails";
import CustomerDetails from "./Steps/CustomerDetails";
import DocumentationUpload from "./Steps/DocumentationUpload";
import Outro from "./Steps/Outro";
import RouterConfirmation from "./Steps/RouterConfirmation";
import UploadRegistration from "./Steps/UploadRegistration";
import Welcome from "./Steps/Welcome";

export const RegistrationSteps = {
	Welcome: { Step: 0, Url: "Welcome" },
	AddressSelection: { Step: 1, Url: "SelectAddress" },
	CustomerDetails: { Step: 2, Url: "EnterDetails" },
	CustomerDetailsConfirmation: { Step: 3, Url: "ConfirmDetails" },
	DocumentUpload: { Step: 4, Url: "UploadDocs" },
	UploadRegistration: { Step: 5, Url: "SubmitRegistration" },
	RouterConfirmation: { Step: 6, Url: "ConfirmRouter" },
	Finish: { Step: 7, Url: "Finish" },
	Support: { Step: 8, Url: "Support" }
}

export default function Registration() {
	const [address, setAddress] = useState(null);
	const [details, setDetails] = useState(null);
	const [files, setFiles] = useState(null);

	let navigate = useNavigate();
	
	function handleOnContinue(data, currentStep) {
		if (currentStep === RegistrationSteps.AddressSelection.Step) {
			setAddress(data);
		}

		if (currentStep === RegistrationSteps.CustomerDetails.Step) {
			setDetails(data);
		}

		if (currentStep === RegistrationSteps.DocumentUpload.Step) {
			setFiles(data);
		}

		for (var page in RegistrationSteps) {
			if (RegistrationSteps[page].Step === currentStep + 1) {
				navigate(RegistrationSteps[page].Url);
			}
		}
	}

	useEffect(() => {
		if (address === null || details === null || files === null) {
			navigate(RegistrationSteps.Welcome.Url);
		}
	}, []);

	useEffect(() => {
		const handlePopstate = (event) => {
			// Prevent going back in history
			window.history.forward();
		};

		// Listen for the popstate event to prevent going back
		window.addEventListener("popstate", handlePopstate);
			// Clean up the event listener when the component unmounts
			return () => {
				window.removeEventListener("popstate", handlePopstate);
			};
	}, []);

  return (
		<Container className="d-flex justify-content-center h-100">
			<Routes>
				<Route
					path="Welcome"
					element={<Welcome onContinue={handleOnContinue}></Welcome>}
				></Route>
				<Route
					path="ConfirmRouter"
					element={<RouterConfirmation onContinue={handleOnContinue} address={address} customer={details}></RouterConfirmation>}
				></Route>
				<Route
					path="SelectAddress"
					element={<AddressSelection onContinue={handleOnContinue} address={address}></AddressSelection>}
				></Route>
				<Route
					path="EnterDetails"
					element={<CustomerDetails onContinue={handleOnContinue} address={address} customer={details}></CustomerDetails>}
				></Route>
				<Route
					path="ConfirmDetails"
					element={<ConfirmCustomerDetails onContinue={handleOnContinue} details={details} address={address}></ConfirmCustomerDetails>}
				></Route>
				<Route
					path="UploadDocs"
					element={<DocumentationUpload files={files} onContinue={handleOnContinue}></DocumentationUpload>}
				></Route>
				<Route
					path="SubmitRegistration"
					element={<UploadRegistration files={files} address={address} details={details} onContinue={handleOnContinue}></UploadRegistration>}
				></Route>
				<Route
					path="Finish"
					element={<Outro preference={details !== null ? details.EmailPreference: "Email"}></Outro>}
				></Route>
			</Routes>
		</Container>
  );
}
