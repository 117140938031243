import { Field } from "formik";
import React from "react";
import { Col, Form, InputGroup, Row } from "react-bootstrap";

export default function Communication(props) {
	return 	<React.Fragment>
				<Form.Group as={Row} className="mb-3 align-items-center">
					<Form.Label column xs={12} sm={4} md={4}>Login Preference</Form.Label>
					<Col xs={12} sm={4} md={2}><Field as={Form.Check} type="radio" value="Email" label="Email" name="EmailPreference"></Field></Col>
					<Col xs={12} sm={4} md={2}><Field as={Form.Check} type="radio" value="Phone" label="Cellphone" name="EmailPreference"></Field></Col>
					<small className="text-danger offset-sm-4">{ props.touched.EmailPreference && props.errors.EmailPreference }</small>
				</Form.Group>
				<Form.Group as={Row} className="mb-3">
					<Form.Label column sm={4}>Email</Form.Label>
					<Col sm={8}>
						<Field isInvalid={ props.touched.Email && props.errors.Email } as={Form.Control} type="email" name="Email"></Field>
						<small className="text-danger">{ props.touched.Email && props.errors.Email }</small>
					</Col>
				</Form.Group>
				<Form.Group as={Row} className="mb-3">
					<Form.Label column sm={4}>Cellphone Number</Form.Label>
					<Col sm={8}>
						<InputGroup>
							<InputGroup.Text style={{ borderEndEndRadius: "0px", borderStartEndRadius: "0px" }} className="border-right-0">+27</InputGroup.Text>
							<Field maxLength={10} isInvalid={ props.touched.CellphoneNumber && props.errors.CellphoneNumber }  as={Form.Control} name="CellphoneNumber"></Field>
						</InputGroup>
						<small className="text-danger">{ props.touched.CellphoneNumber && props.errors.CellphoneNumber }</small>
					</Col>
				</Form.Group>
				
				<Form.Group as={Row} className="mb-3 align-items-center">
					<Form.Label column xs={12} sm={4} md={4}>Language Preference</Form.Label>
					<Col xs={12} sm={4} md={2}><Field as={Form.Check} type="radio" value="EN" name="LanguagePreference" label="English"></Field></Col>
					<Col xs={12} sm={4} md={2}><Field as={Form.Check} type="radio" value="ZU" name="LanguagePreference" label="Zulu"></Field></Col>
					<small className="text-danger offset-sm-4">{ props.touched.LanguagePreference && props.errors.LanguagePreference }</small>
				</Form.Group>
			</React.Fragment>
}