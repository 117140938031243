import { Form , Row, Col, InputGroup } from "react-bootstrap";
import { Field, useFormikContext } from "formik";
import * as yup from 'yup';
import { useEffect, useRef } from "react";

export function getUsernameValidation(preferenceField) {
	return yup
			.string()
			.when(preferenceField, {
				is: "Email",
				then: yup.string().required("Email is required.").email("Invalid Email Format."),
				otherwise: yup.string().matches(/^\d{9,10}$/, "Phone number is not valid.").required("Cellphone Number is required."),
			});
}

export function Input({ busy, usernameField, preferenceField }) {
	const preferenceRef = useRef("");
	const { errors, touched, values, setFieldValue } = useFormikContext();
	const preference = values[preferenceField];

	useEffect(() => {
		if(values && setFieldValue && usernameField && preferenceRef.current) {
			if(preferenceRef.current !== preference) {
				setFieldValue(usernameField, "", true);
			}
		}

		preferenceRef.current = preference;

	}, [preference])
	
	return 	<>
				<Form.Label className="text-left" column sm={4}>{values[preferenceField] === "Email" ? "Email Address" : "Cellphone Number"}</Form.Label>
				<Col sm={8}>
					<InputGroup>
					{
						values[preferenceField] === "Phone" &&
						<InputGroup.Text style={{ borderEndEndRadius: "0px", borderStartEndRadius: "0px" }} className="border-right-0">+27</InputGroup.Text>
					}
						<Field maxLength={(values[preferenceField] === "Phone" ? 10 : null)} disabled={busy} isInvalid={errors[usernameField] && (touched[usernameField] || values[usernameField])} as={Form.Control} name={ usernameField } ></Field>
					</InputGroup>
					<div className="text-left">
						<small className="text-danger text-left">{(touched[usernameField] || values[usernameField]) && errors[usernameField]}</small>
					</div>
				</Col>
			</>
}

export function UsernameInput({ usernameField, preferenceField, busy }) {
	const { errors, touched } = useFormikContext();

	return <>
		<Form.Group as={Row} className="mb-3 align-items-center">
			<Form.Label column xs={12} sm={4} md={4} className="text-left">Login Type</Form.Label>
			<Col xs={12} sm={4} md={2}><Field as={Form.Check} type="radio" value="Phone" label="Cellphone" name={ preferenceField }></Field></Col>
			<Col xs={12} sm={4} md={2}><Field as={Form.Check} type="radio" value="Email"  label="Email" name={ preferenceField } ></Field></Col>
			<small className="text-danger offset-sm-4">{touched[preferenceField] && errors[preferenceField]}</small>
		</Form.Group>
		<Form.Group as={Row} className="mb-3 mt-4">
			<Input busy={ busy } preferenceField={ preferenceField } usernameField={ usernameField } />
		</Form.Group>
	</>
}