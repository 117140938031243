import { Routes, Route, Navigate  } from "react-router-dom";
import LandingPage from "./LandingPage";
import ResetPassword from "./Portal/Pages/ResetPassword";
import Portal from "./Portal/Portal";
import Registration from "./Registration/Registration";
import ResubmitFile from "./ResubmitFile";

export default function Main() {
		return 	<Routes>
					<Route path="/" exact element={ <LandingPage></LandingPage> }></Route>
					<Route path="/Resubmit/:customerId" exact element={ <ResubmitFile></ResubmitFile> }></Route>
					<Route path="/Register/*" element={ <Registration></Registration> }></Route>
					<Route path="/Portal/*" element={ <Portal></Portal> }></Route>
					<Route path="/ResetPassword/:resetId" element={ <ResetPassword></ResetPassword> }></Route>
					<Route path="*" element={ <Navigate to="/" /> }></Route>
            	</Routes>
}