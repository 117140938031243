import { DateTime } from "luxon";
import { Card, Col, Row, Table } from "react-bootstrap";

export default function RechargeHistory(props) {
	return 	<Card>
				<Card.Header>
					<h4>Purchase History</h4>
				</Card.Header>
				<Card.Body>
					<Row>
						<Col>
							<Table>
								<thead>
									<tr>
										<th>Date</th>
										<th className="d-none d-md-table-cell">Method</th>
										<th className="d-none d-md-table-cell">Package</th>
										<th>Amount</th>
									</tr>
								</thead>
								<tbody>
									{
										props.rechargeHistory && props.rechargeHistory.length > 0 &&
										props.rechargeHistory.map((recharge, index) =>
											<tr>
												<td>{ DateTime.fromISO(recharge.Date).toFormat("dd/MM/yyyy") }</td>
												<td className="d-none d-md-table-cell">{ recharge.Method }</td>
												<td className="d-none d-md-table-cell">{ recharge.Package }</td>
												<td>R { recharge.Amount }</td>
											</tr>
										)
									}
									{
										(!props.rechargeHistory || props.rechargeHistory.length === 0) &&
										<tr>
											<td colSpan={4} className="text-center"> * No Recharges Yet * </td>
										</tr>
									}
								</tbody>
							</Table>
						</Col>
					</Row>
				</Card.Body>
			</Card>
}