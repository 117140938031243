import { DateTime } from "luxon";
import React  from "react";
import { Card, Col, Row } from "react-bootstrap";
import { useSessionStore } from "../../../../Stores/SessionStore";

export default function PackageDetails(props) {
	const store = useSessionStore();

	return 	<Card className="h-100">
				<Card.Header>
					<h4>Current Package</h4>
				</Card.Header>
				<Card.Body>
					{
						props.details && props.details.RadiusDetails &&
						<React.Fragment>
							<div className="text-primary">
								{
									store.CustomerHasActiveService() &&
									<span>{ props.details.Customer.SuccessfulRecharges[0].Package } { props.details.Customer.SubscriptionToken ? "(Subscription)" : "" }</span>
								}
								{
									store.CustomerHasActiveService() === false &&
									<span>None</span>
								}
							</div>
							{
								store.CustomerHasActiveService() &&
								<React.Fragment>
									<div className="text-primary">Expiry Date: { DateTime.fromISO(props.details.RadiusDetails.ExpiryDate).toFormat("dd/MM/yyyy") }</div>
									{
										props.details.Customer && props.details.Customer.SuccessfulRecharges[0] && 
										<h2 className="text-secondary">R{ props.details.Customer.SuccessfulRecharges[0].Amount }</h2>
									}
									{
										props.details.Customer.SubscriptionToken &&
										<Row className="d-flex justify-content-center pt-2">
											<Col className="d-flex justify-content-center" md={6} lg={ 12 } xl={8}>
												<a href={`${store.Config.payfastUrl}recurring/update/${props.details.Customer.SubscriptionToken}?return=${encodeURIComponent(store.Config.payfastSuccessUrl)}`} className="text-white btn btn-primary mt-2 w-100">Update Credit Card</a>
											</Col>
										</Row>
									}
								</React.Fragment>
							}
						</React.Fragment>
					}
					{
						(!props.details || !props.details.RadiusDetails) &&
						<span>None</span>
					}
				</Card.Body>
			</Card>
}