import { DateTime } from "luxon";
import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";
import { useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useSessionStore } from "../../../../Stores/SessionStore";
import BusyIndicator from "../../../Core/BusyIndicator";
import CannotRechargeWarningModal from "../../../Core/CannotRechargeWarningModal";
import PageTitle from "../../../Core/PageTitle";
import CancelSubscriptionModal from "./CancelSubscriptionModal";
import SubscriptionPaymentFailedModal from "./SubscriptionPaymentFailedModal";
import ComingSoonModal from "./ComingSoonModal";
import PackageDetails from "./PackageDetails";
import RechargeHistory from "./RechargeHistory";
import ServiceDetails from "./ServiceDetails";
import UserProfileDetails from "./UserProfileDetails";

const Home = observer(function Home(props) {
	const [showComingSoonModal, setShowComingSoonModal] = useState(false);
	const [showCannotRechargeModal, setShowCannotRechargeModal] = useState(false);
	const [showCancelSubscriptionModal, setShowCancelSubscriptionModal] = useState(false);

	const store = useSessionStore();
	const navigate = useNavigate();

	useEffect(() => {
		async function getDetails() {
			store.LoadCustomerDetails();			
		}

		getDetails();
	}, []);

	function handleCancelSubscriptionModalClosed(refresh) {
		setShowCancelSubscriptionModal(false);

		if(refresh) {
			store.LoadCustomerDetails();
		}
	}

	function handleSubscriptionPaymentFailedModalClosed(recharge) {
		store.SetSubscriptionStatusActive(store.CustomerDetails.SubscriptionStatus === "ACTIVE" || store.CustomerDetails.SubscriptionStatus === "COMPLETE");
		
		if (recharge) handleRechargeClicked();
	}

	function handleRechargeClicked() {
		navigate("../Recharge");
	}

	return 	<div>
				{
					store.CustomerDetails &&
					<React.Fragment>
						<PageTitle text="Customer Dashboard"></PageTitle>
						<Row className="mb-5 d-block d-lg-none ">
							<Col xs={ 12 } lg={4} className="mb-lg-0 mb-3">
								<PackageDetails details={ store.CustomerDetails }></PackageDetails>
							</Col>
						</Row>
						<Row className="mb-0 mb-lg-3">
							<Col xs={ 12 } lg={3} className="mb-lg-0 mb-3">
								{
									(!store.CustomerDetails.Customer.SubscriptionToken || !(store.CustomerDetails.SubscriptionStatus === "ACTIVE" || store.CustomerDetails.SubscriptionStatus === "COMPLETE")) &&
									<Button variant="outline-primary" className="w-100" onClick={ handleRechargeClicked }>Purchase</Button>
								}
								{
									store.CustomerDetails.Customer.SubscriptionToken && (store.CustomerDetails.SubscriptionStatus === "ACTIVE" || store.CustomerDetails.SubscriptionStatus === "COMPLETE") &&
									<Button variant="outline-primary" className="w-100" onClick={ e => setShowCancelSubscriptionModal(true) }>Cancel Subscription</Button>
								}
							</Col>
							<Col xs={ 12 } lg={3} className="mb-lg-0 mb-3">
								<Button variant="outline-primary" className="w-100" onClick={ e => setShowComingSoonModal(true) }>Purchase History</Button>
							</Col>
							<Col xs={ 12 } lg={3} className="mb-lg-0 mb-3">
								<Button variant="outline-primary" className="w-100" onClick={ e => setShowComingSoonModal(true) }>Traffic Usage</Button>
							</Col>
							<Col xs={ 12 } lg={3} className="mb-lg-0 mb-3">
								<Button variant="outline-primary" className="w-100" onClick={ e => navigate("../Profile") }>Edit User Profile</Button>
							</Col>
						</Row>
						<Row className="mb-3 mt-5">
							<Col xs={ 12 } lg={4} className="d-none d-lg-block mb-lg-0 mb-3">
								<PackageDetails details={ store.CustomerDetails }></PackageDetails>
							</Col>
							<Col xs={ 12 } lg={4} className="mb-lg-0 mb-3">
								<UserProfileDetails details={ store.CustomerDetails.Customer }></UserProfileDetails>
							</Col>
							<Col xs={ 12 } lg={4} className="mb-lg-0 mb-3">
								<ServiceDetails details={ store.CustomerDetails.RadiusDetails } rechargeHistory={ store.CustomerDetails.Customer.SuccessfulRecharges }></ServiceDetails>
							</Col>
						</Row>
						<Row>
							<Col className="mb-3 mt-5">
								<RechargeHistory rechargeHistory={ store.CustomerDetails.Customer.SuccessfulRecharges }></RechargeHistory>
							</Col>
						</Row>
						<CancelSubscriptionModal onClose={ handleCancelSubscriptionModalClosed } show={ showCancelSubscriptionModal } details={ store.CustomerDetails }></CancelSubscriptionModal>
						<SubscriptionPaymentFailedModal onClose={ handleSubscriptionPaymentFailedModalClosed } show={ store.SubscriptionStatus === null && store.CustomerDetails.SubscriptionStatus !== null && !(store.CustomerDetails.SubscriptionStatus === "ACTIVE" || store.CustomerDetails.SubscriptionStatus === "COMPLETE") } details={ store.CustomerDetails }></SubscriptionPaymentFailedModal>
					</React.Fragment>
				}
				<BusyIndicator show={ store.CustomerDetails === null }></BusyIndicator>
				<ComingSoonModal show={ showComingSoonModal } onOKClicked={ e => setShowComingSoonModal(false) }></ComingSoonModal>
				<CannotRechargeWarningModal show={ showCannotRechargeModal } onOKClicked={ e => setShowCannotRechargeModal(false) }></CannotRechargeWarningModal>
			</div>
});

export default Home;