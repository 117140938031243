import React, { useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { RegistrationSteps } from "../Registration";
import StepContainer from "./StepContainer";
import Router from "../../../Images/Router.png"
import { useSessionStore } from "../../../Stores/SessionStore";

export default function RouterConfirmation(props) {
	const store = useSessionStore();

	const [showingError, showError] = useState(false);
	const [errorMessage, setErrorMessage] = useState("");

	async function onIWantARouter() {
		
		let result = await store.CustomersService.SendRouterNeededEmail(props.address.Id, props.customer);

		if (result && result.Success) {
			props.onContinue(null, RegistrationSteps.RouterConfirmation.Step);
		} else {
			if (result) {
				setErrorMessage(result.Message);				
			} else {
				setErrorMessage("An error occurred. Please retry.");
			}

			showError(true);
		}
	}

	return 	<StepContainer title="Do you have a Zing Router installed in your home already?">
				<React.Fragment>
					<Row className="text-center mb-5">
						<Col className="d-flex justify-content-center">
							<img style={{ height: "350px" }} src={ Router }></img>
						</Col>
					</Row>
					<Row>
						<Col xs={12} lg={6} className="text-center mb-3 mb-lg-0">
							<Button className="w-100" onClick={ e => props.onContinue(null, RegistrationSteps.RouterConfirmation.Step) }>Yes, I do</Button>
						</Col>
						<Col xs={12} lg={6} className="text-center">
							<Button className="w-100" onClick={ () => onIWantARouter() }>No, I want one</Button>
						</Col>
					</Row>
					{
				showingError && (
					<div>
						<Row className="text-center mt-2">
							<Col>
								<h6 className="text-danger">An error occurred. {errorMessage && `: ${errorMessage}`} Please retry.</h6>
							</Col>
						</Row>
						<Row className="mt-2 d-flex justify-content-center">
							<Col xs={12} lg={6}>
								<Button className="w-100" onClick={() => onIWantARouter()}>Retry</Button>
							</Col>
						</Row>
					</div>
				)
			}
				</React.Fragment>
			</StepContainer>
}