import { Form, Formik } from "formik";
import { observer } from "mobx-react-lite";
import { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { useSessionStore } from "../../Stores/SessionStore";
import NewPasswordFields from "../Core/NewPasswordFields";
import * as yup from 'yup';
import { runInAction } from "mobx";

const SetOwnPasswordModal = observer(function SetOwnPasswordModal(props) {
	const [savingPassword, setSavingPassword] = useState(false);
	const [error, setError] = useState(null);

	const store = useSessionStore();

	const validationSchema =
        yup.object({
			NewPassword: yup.string().required("New Password is required.").min(8, "New Password must be at least 8 characters."),
			ConfirmNewPassword: yup.string().when('NewPassword', (NewPassword, schema) => {
				return schema.test({
				  test: ConfirmNewPassword => NewPassword === ConfirmNewPassword,
				  message: "Password Confirmation should match New Password"
				})})}
        ).required();

	async function handleFormSubmitClicked(values) {
		setError(null);
		setSavingPassword(true);
		var result = await store.CustomersService.SetOwnPassword(values.NewPassword, values.ConfirmNewPassword);
		setSavingPassword(false);

		if(result && result.Success) {
			runInAction(() => {
				store.CustomerDetails.Customer.SetOwnPassword = true;
			})
		} else {
			if(result) {
				setError("An error occurred setting your password: " + result.Message);
			} else {
				setError("An error occurred setting your password, please contact support.");
			}
		}
	}

	return 	<Modal size="lg" show={ store.CustomerDetails && store.CustomerDetails.Customer.SetOwnPassword === false }>
				{
					store.CustomerDetails &&
					<Formik validationSchema={ validationSchema } initialValues={{ NewPassword: "", ConfirmNewPassword: "" }} onSubmit={ handleFormSubmitClicked }>
						{({ errors, touched }) => (
							<Form>
								<Modal.Header>
									<h3>
										Set Your Password
									</h3>
								</Modal.Header>
								<Modal.Body>
									<p>For additional security, please enter in a new password.</p>
									<NewPasswordFields touched={{ NewPassword: true, ConfirmNewPassword: true }} errors={errors}></NewPasswordFields>
									{
										error &&
										<small className="text-danger">{ error }</small>
									}
								</Modal.Body>
								<Modal.Footer>
									<Button type="submit" disabled={ savingPassword }>Save</Button>
								</Modal.Footer>
							</Form>
								)}
					</Formik>
				}
			</Modal>
});

export default SetOwnPasswordModal;