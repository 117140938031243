import React, { useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { RegistrationSteps } from "../Registration";
import FileUploadBlock from "./FileUploadBlock";
import StepContainer from "./StepContainer";

export default function DocumentationUpload(props) {
	const [id, setId] = useState((props.files ? props.files.IDDocument : null));
	const [showingIDError, showIDError] = useState(false);

	function handleContinueClicked() {
		if(!id) {
			showIDError(true);
			return;
		} else {
			showIDError(false);
		}

		props.onContinue({ IDDocument: id}, RegistrationSteps.DocumentUpload.Step);
	}

	function handleIDUploaded(file, fileName) {
		setId({ Content: file, FileName: fileName });
	}

	return	<StepContainer title="Upload documentation for RICA purposes:">
				<React.Fragment>
					<Row className="mb-5">
						<Col>
							<h4 className="mb-4">We require the following documentation to be uploaded in order to adhere to the RICA Act 70 of 2002.</h4>
						</Col>
					</Row>
					<Row  className="text-center d-none d-md-flex">
						<Col xs={12} md={12} xl={12} className="offset-xl-1">

						</Col>
					</Row>
					<Row className="text-center">
						<Col xs={12} md={12} xl={12} className=" mb-3">
							<FileUploadBlock filename={ (id ? id.FileName : null ) } file={ (id ? id.Content : null) } onFileChanged={ handleIDUploaded } name="IdBook" description="ID Book or Card / Foreign Passport"></FileUploadBlock>
						</Col>
					</Row>
					<Row className="text-center mb-5">
						<Col className="d-flex justify-content-center">
							<h6 className="text-danger">{ (showingIDError ? "A file containing an image of your ID Document is required." : " ")}</h6>
						</Col>
					</Row>
					<Row className="text-center">
						<Col xs={12} lg={6} className="offset-xs-0 offset-lg-3">
							<Button className="w-100" onClick={ handleContinueClicked }>Continue</Button>
						</Col>
					</Row>
				</React.Fragment>
			</StepContainer>
}