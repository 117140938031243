import { DateTime } from "luxon";
import React, { useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useSessionStore } from "../../../../Stores/SessionStore";
import CannotRechargeWarningModal from "../../../Core/CannotRechargeWarningModal";
import PageTitle from "../../../Core/PageTitle";
import Confirmation from "./Confirmation";
import ServiceGrid from "./Services/ServiceGrid";

export default function Recharge() {
	const [confirmingRecharge, showConfirmRecharge] = useState(false);
	const [payingForRecharge, showRechargePayment] = useState(false);
	const [showCannotRechargeModal, setShowCannotRechargeModal] = useState(false);
	const [rechargeDetails, setRechargeDetails] = useState(null);

	const store = useSessionStore();

	async function handleServiceClicked(category, product) {
		//before we allow recharges, we need to ensure they can actually recharge their account
		if(store.CustomerDetails === null) {
			await store.LoadCustomerDetails();
		}

		if(store.CustomerDetails && store.CustomerDetails.RadiusDetails) {
			var expiryDate = DateTime.fromISO(store.CustomerDetails.RadiusDetails.ExpiryDate);

			//customer can only recharge in the following circumstances:
			// 1. They are on the demo package (i.e have not yet done a successful recharge)
			// 2. Their package has expired, or they are recharging one the day it is expiring (Recharge to any package)
			// 3. Their package has not yet expired and they are recharging before the expiry date (Recharge at the same speed only)
			if(store.CustomerDetails.Customer.SuccessfulRecharges.length > 0) {
				var lastRecharge = store.CustomerDetails.Customer.SuccessfulRecharges[0];

				if(expiryDate.diffNow("days").days > 0 && product.ServiceId !== lastRecharge.ServiceId) {
					setShowCannotRechargeModal(true);
					return;
				}
			}

			setRechargeDetails({ Category: category, Product: product });
			showConfirmRecharge(true);
		}
	}

	function handleRechargeConfirm(answer) {
		if(answer === false) {
			setRechargeDetails(null);
			showConfirmRecharge(false);
		} else {
			showConfirmRecharge(false);
			showRechargePayment(true);
		}
	}

	return 	<React.Fragment>
				<PageTitle text={rechargeDetails === null ? "Packages" : "Complete Purchase"}></PageTitle>
				<Row className="bg-white">
					<Col xs={12} md={12} xl={8} className="offset-0 offset-xl-2 pl-5 pr-5 pb-4">
						{
							confirmingRecharge === false &&
							<ServiceGrid onServiceClicked={ handleServiceClicked }></ServiceGrid>
						}
						{
							confirmingRecharge && rechargeDetails &&
							<Confirmation category={ rechargeDetails.Category } product={ rechargeDetails.Product } onSubmit={ handleRechargeConfirm }></Confirmation>
						}
					</Col>
					<CannotRechargeWarningModal show={ showCannotRechargeModal } onOKClicked={ e => setShowCannotRechargeModal(false) }></CannotRechargeWarningModal>
				</Row>
			</React.Fragment>
}