import { DateTime } from "luxon";
import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { useSessionStore } from "../../../../Stores/SessionStore";

export default function CancelSubscriptionModal(props) {
	const store = useSessionStore();
	const [cancelling, setCancelling] = useState(false);
	const [cancelled, setCancelled] = useState(false);
	const [cancelError, setCancelError] = useState(false);

	useEffect(() => {
		if(props.show) {
			setCancelling(false);
			setCancelled(false);
			setCancelError(false);
		}
	}, [props.show])

	async function handleYesClicked() {
		setCancelling(true);

		var result = await store.CustomersService.CancelSubscription(props.details.Customer.Id);

		if(result && result.Success) {
			setCancelling(false);
			setCancelled(true);
		} else {
			setCancelling(false);
			setCancelError(true);
		}
	}

	return 	<Modal show={ props.show }>
				<Modal.Header>
					<h3>
						Cancel Subscription
					</h3>
				</Modal.Header>
				{
					props.details && props.details.RadiusDetails &&
					<Modal.Body>
						{
							!cancelled && !cancelError &&
							<React.Fragment>
								<p>This will cancel your subscription to the { props.details.RadiusDetails.ServiceName } service.</p>
								<p>You will continue to have access until { DateTime.fromISO(props.details.RadiusDetails.ExpiryDate).toFormat("dd/MM/yyyy") }.</p>
								<p>Would you like to continue?</p>
							</React.Fragment>
						}
						{
							cancelled && !cancelError &&
							<React.Fragment>
								<p>Your service to { props.details.RadiusDetails.ServiceName } has been successfully cancelled.</p>
								<p>You will continue to have access until { DateTime.fromISO(props.details.RadiusDetails.ExpiryDate).toFormat("dd/MM/yyyy") }.</p>
							</React.Fragment>
						}
						{
							cancelError &&
							<React.Fragment>
								<p>An error ocurred attempting to cancel your service.</p>
								<p>Please contact Zing Fibre Support.</p>
							</React.Fragment>
						}
					</Modal.Body>
				}
				<Modal.Footer>
					{
						!cancelled && !cancelError &&
						<React.Fragment>
							<Button disabled={ cancelling } onClick={ handleYesClicked }>{ cancelling ? "Cancelling..." : "Yes" }</Button>
							<Button disabled={ cancelling } onClick={ e => props.onClose(false) }>No</Button>
						</React.Fragment>
					}
					{
						(cancelled || cancelError) &&
						<Button onClick={ e => props.onClose(cancelled) }>OK</Button>
					}
				</Modal.Footer>
			</Modal>
}