import React from "react"
import { Col, Row } from "react-bootstrap";
import Loader from "../../Images/loader.svg"

export default function BusyIndicator(props) {

	if(props.show) {
		return 	<Row className="text-center">
					<Col>
						<img src={ Loader }></img>
					</Col>
				</Row>
	} else {
		return false;
	}
	return
}