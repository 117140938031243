import React from "react";
import { Card, Col, Row } from "react-bootstrap";
import { useSessionStore } from "../../../../Stores/SessionStore";

export default function UserProfileDetails(props) {
	const store = useSessionStore();

	function getLanguageText(language) {
		switch (language) {
			case "EN":
				return "English";
			case "ZU":
				return "Zulu";
			default:
				break;
		}
	}

	return 	<Card  className="h-100">
				<Card.Header>
					<h4>User Profile</h4>
				</Card.Header>
				<Card.Body>
					{
						props.details &&
						<React.Fragment>
							<Row>
								<Col>Username:</Col>
								<Col>{ props.details.CellphoneNumber }</Col>
							</Row>
							<Row>
								<Col>Name:</Col>
								<Col>{ `${props.details.FirstName} ${props.details.Surname}` }</Col>
							</Row>
							<Row>
								<Col>Cell:</Col>
								<Col>{ props.details.CellphoneNumber }</Col>
							</Row>
							<Row>
								<Col>Language:</Col>
								<Col>{ getLanguageText(props.details.LanguagePreference) }</Col>
							</Row>
						</React.Fragment>
					}

				</Card.Body>
			</Card>
}