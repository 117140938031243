import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useSessionStore } from "../../../../../Stores/SessionStore";
import BusyIndicator from "../../../../Core/BusyIndicator";
import ServicePeriod from "./ServicePeriod";
import ServiceSpeed from "./ServiceSpeed";
import ConfirmAddressModal from "../ConfirmAddressModal";

export default function ServiceGrid(props) {
	const store = useSessionStore();

	const [products, setProducts] = useState(null);

	const [showConfirmationModal, setShowConfirmationModal] = useState(false);
	const [selectedPackage, setselectedPackage] = useState(null);

	useEffect(() => {
		async function getProducts() {
			var results = await store.ProductsService.GetProducts();

			if(results && results.Success) {
				setProducts(results.Data);
			}
		}

		getProducts();
	}, []);

	function getProductCategories() {
		let categories = []

		if(products) {
			for(let i = 0; i < products.length; i++) {
				let category = categories.find((p) => p.Category === products[i].Category);

				if(category) {
					category.Products.push(products[i]);
				} else {
					categories.push({ Category: products[i].Category, Products: [products[i]] });
				}
			}
		}

		//sort the products
		for(let i = 0; i < categories.length; i++) {
			categories[i].Products = categories[i].Products.sort((a, b) => a.Price - b.Price);
		}
		
		//sort the categories
		try	{
			categories.sort((a, b) => parseInt(a.Category.split(" ")[0]) - parseInt(b.Category.split(" ")[0]));
		} catch (e) { }
		
		return categories;
	}

	function handlePackageClicked(category, product) {
		setselectedPackage({ Category: category, Product: product });
		setShowConfirmationModal(true);
	}

	function handleConfirmClosed(confirmed) {
		setShowConfirmationModal(false);

		if(confirmed) {
			props.onServiceClicked(selectedPackage.Category, selectedPackage.Product);
		}
	}

	const categories = getProductCategories();

	return 	<React.Fragment>
			{
				products &&
				<React.Fragment>
					<h5 className="mb-5 text-center">Please select from the following available packages</h5>
					{
						categories.map((category, index) =>
						<React.Fragment>
							<Row className="d-flex align-items-center pt-4">
								<Col className="d-none d-lg-block pb-4" lg={ 2 } xl={2}>
									<ServiceSpeed speed={ category.Category }></ServiceSpeed>
								</Col>
								<Col xs={ 12 } className="d-flex justify-content-center d-lg-none pb-4">
									<ServiceSpeed speed={ category.Category }></ServiceSpeed>
								</Col>
								{
									category.Products.map((product) =>
									<Col xs={ 6 } sm={ 4 } md={ 3 } lg={ 2 } xl={2}>
										<div className="pb-4">
											<ServicePeriod onClick={ e => handlePackageClicked(category.Category, product) } product={ product }></ServicePeriod>
										</div>
									</Col>)
								}
							</Row>
							{
								(index < categories.length - 1) &&
								<hr></hr>
							}
						</React.Fragment>
						)
					}
					</React.Fragment>
				}
				<BusyIndicator show={ products === null }></BusyIndicator>
				<ConfirmAddressModal show={ showConfirmationModal } onClose={ handleConfirmClosed } />
			</React.Fragment>

}