import { faCheckCircle, faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Button, Modal } from "react-bootstrap";

export default function CashPaymentAlertModal(props){
    return(
        <Modal size="lg" show={props.show}>
            <Modal.Header className="text-white" style={{ backgroundImage: "linear-gradient(130deg, #009845 0%, #FFE200 100%)"}}>
                { props.title }!
            </Modal.Header>
            <Modal.Body>
                {
                    props.title === "Success" &&
                    <div className="text-center">
                        <FontAwesomeIcon icon={faCheckCircle} color="green" size="3x"></FontAwesomeIcon>
                        <div> Your Pay@ code </div>
                        <div className="font-weight-bold"> { props.code } </div>
                        <div> has been sent to </div>
                        <div className="font-weight-bold"> { props.contactPreference } </div>
                    </div>
                }
                {
                    props.title === "Error" &&
                    <div className="text-center">
                        <FontAwesomeIcon icon={faTimesCircle} color="green" size="3x"></FontAwesomeIcon>
                        <div>{ props.message }</div>
                    </div>
                }
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={ () => props.closeModal(false) } href="/#/Portal/Home"> OK </Button>
            </Modal.Footer>
        </Modal>
    )
}