import { Navigate, Route, Routes, useNavigate } from "react-router-dom";
import { Row, Col, Container, Button } from "react-bootstrap"
import { useSessionStore } from "../../Stores/SessionStore";
import Home from "./Pages/Home/Home";
import Logo from "../Core/Logo";
import React from "react";
import { observer } from "mobx-react-lite";
import Recharge from "./Pages/Recharge/Recharge";
import UserProfile from "./Pages/UserProfile/UserProfile";
import { useEffect } from "react";
import SetOwnPasswordModal from "./SetOwnPasswordModal";

const Portal = observer(function Portal() {
	const store = useSessionStore();
	const navigate = useNavigate();

	function handleLogoutClicked() {
		store.Logout();
		navigate("/");
	}

	useEffect(() => {
		if(store.UserIsLoggedIn) {
			store.LoadCustomerDetails();
		} else {
			navigate("/");
		}

	}, [store.UserIsLoggedIn])

	return 	<Container fluid>
				<div className="p-2 p-md-4">
					<Row className="d-flex align-items-center my-4">
						<Col xs={12} md={8} lg={9}>
							<Logo></Logo>
						</Col>
						<Col className="mt-4 mt-md-0">
							<Button className="w-100 mb-3" style={{backgroundImage: "linear-gradient(130deg, #009845 0%, #FFE200 100%)"}} onClick={ e => navigate("Home") }>Home</Button>
							<Button onClick={ handleLogoutClicked } style={{backgroundImage: "linear-gradient(150deg, #FFEF00 0%, #FF7800 100%)"}} className="w-100">Logout</Button>
						</Col>
					</Row>
					{
						store.UserIsLoggedIn &&
						<React.Fragment>
							<Routes>
								<Route path="Home" element={ <Home></Home> }></Route>
								<Route path="Recharge" element={ <Recharge></Recharge> }></Route>
								<Route path="Profile" element={ <UserProfile></UserProfile> }></Route>
								<Route path="*" element={ <Navigate to="Home" /> }></Route>
							</Routes>
							<SetOwnPasswordModal></SetOwnPasswordModal>
						</React.Fragment>
					}
				</div>
			</Container>

});

export default Portal;