import { useState } from "react";
import { Col, Row, Button, FormGroup, Form } from "react-bootstrap";
import { AsyncTypeahead } from "react-bootstrap-typeahead";
import { useSessionStore } from "../../../Stores/SessionStore";
import 'react-bootstrap-typeahead/css/Typeahead.css';
import { RegistrationSteps } from "../Registration";
import StepContainer from "./StepContainer";

export default function AddressSelection(props) {
	const [loadingAddresses, setLoadingAddresses] = useState(false);
	const [addresses, setAddresses] = useState([]);
	const [selectedAddress, setSelectedAddress] = useState(props.address);
	const [showAddressError, setShowAddressError] = useState(false);

	const store = useSessionStore();

	async function handleAddressSearch(query) {
        setLoadingAddresses(true);
        var addresses = await store.CustomersService.SearchAddress(query);

        if(addresses && addresses.Success) {
            setAddresses(addresses.Data);
            setLoadingAddresses(false);
        }
    }

	function handleAddressChange(address) {

		if(address && address[0]) {
			setSelectedAddress(address[0]);
			setShowAddressError(false);
		}
    }

	function onContinueClicked() {
		if(selectedAddress) {
			setShowAddressError(false);
			props.onContinue(selectedAddress, RegistrationSteps.AddressSelection.Step);
		} else {
			setShowAddressError(true);
		}
	}

	function getDefaultInput() {
		if(selectedAddress) {
			return getAddressLabel(selectedAddress);
		}

		return "";
	}

	function getAddressLabel(address) {
		if(address) {
			return `${address.ERF}, ${address.StreetAddress}, ${address.MDUName ? address.MDUName + ", " : ""}${address.MDUBlock ? address.MDUBlock + ", " : ""}${address.MDUUnitNumber ? "UNIT " + address.MDUUnitNumber + ", " : ""}${address.Township}`;
		}
	}

	return 	<StepContainer title="Please type in your physical address below and select it from the list that appears. (This MUST be the address you are requiring Internet Access at):">
				<Row className="text-center">
					<Col className="d-flex justify-content-center">
						<Form className="w-100">
							<FormGroup>
								<AsyncTypeahead filterBy={() => true} useCache={false} labelKey={ add => getAddressLabel(add)} 
												inputProps={{ style: { width: "100%" } }}
												placeholder="Type Your Address" onChange={ handleAddressChange } defaultInputValue={ getDefaultInput() }
												isLoading={ loadingAddresses } onSearch={ handleAddressSearch } options={ addresses }></AsyncTypeahead>
							</FormGroup>
						</Form>
					</Col>
				</Row>
				{
					showAddressError &&
					<Row className="text-center mt-3">
						<Col className="d-flex justify-content-center">
							<h6 className="text-danger">Please select your address before continuing.</h6>
						</Col>
					</Row>
				}
				<Row className="mt-5">
					<Col xs={12} lg={6} className="text-center mb-3 mb-lg-0">
						<Button className="w-100" onClick={ onContinueClicked }>Confirm Address</Button>
					</Col>
					<Col xs={12}  lg={6} className="text-center">
						<Button className="w-100" onClick={ e => window.open("https://www.zingfibre.co.za/contact-us/#getstarted", '_blank').focus() }>My address is not listed</Button>
					</Col>
				</Row>
			</StepContainer>
}