import { Form, Formik } from "formik";
import React from "react";
import { useState } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import NewPasswordFields from "../../Core/NewPasswordFields";
import * as yup from 'yup';
import { useNavigate, useParams } from "react-router-dom";
import Logo from "../../Core/Logo";
import { useSessionStore } from "../../../Stores/SessionStore";

export default function ResetPassword() {
	const [resettingPassword, setResettingPassword] = useState(false);
	const [resetComplete, setResetComplete] = useState(false);
	const [error, setError] = useState(null);

	const params = useParams();
	const navigate = useNavigate();
	const store = useSessionStore();

	const validationSchema =
	yup.object({
		NewPassword: yup.string().required("New Password is required.").min(8, "New Password must be at least 8 characters."),
		ConfirmNewPassword: yup.string().when('NewPassword', (NewPassword, schema) => {
			return schema.test({
			  test: ConfirmNewPassword => NewPassword === ConfirmNewPassword,
			  message: "Password Confirmation should match New Password"
			})})}
	).required();

	async function handleFormSubmitClicked(values) {
		setError(null);
		setResettingPassword(true);
		var result = await store.CustomersService.ResetPassword(params.resetId, values.NewPassword, values.ConfirmNewPassword);
		setResettingPassword(false);

		if(result && result.Success) {
			setResetComplete(true);
		} else {
			if(result) {
				setError(result.Message);
			} else {
				setError("An error occurred and your password could not be reset, please contact support.");
			}
		}
	}

	return 	<Container fluid>
				<div className="d-flex flex-column p-2 p-md-4">
					<Row className="d-flex align-items-center my-4 mb-5">
						<Col xs={12} md={8} lg={7}>
							<Logo></Logo>
						</Col>
					</Row>
					<Row>
						<Col xs={12} lg={8} className="offset-0 offset-lg-2 border p-3 p-lg-5">
							<h4 className="mb-3 mb-md-5 text-center text-lg-left">Reset Your Password</h4>
							{
								resetComplete === false &&
								<Formik validationSchema={ validationSchema } initialValues={{ NewPassword: "", ConfirmNewPassword: "" }} onSubmit={ handleFormSubmitClicked }>
									{({ errors, touched }) => (
										<Form>
											<NewPasswordFields touched={{ NewPassword: true, ConfirmNewPassword: true }} errors={errors}></NewPasswordFields>
											{
												error &&
												<small className="text-danger">{ error }</small>
											}

											<Row>
												<Col xs={12} md={8} lg={6} xl={4} className="offset-0 offset-md-2 offset-lg-3 offset-xl-4">
													<Button className="w-100 my-3 mt-lg-5" type="submit" disabled={ resettingPassword }>Reset Password</Button>
												</Col>
											</Row>
										</Form>
											)}
								</Formik>
							}
							{
								resetComplete &&
								<React.Fragment>
									<p>Your password has been successfully reset. You can now return to the home page and login using your new password.</p>
									<Row>
										<Col xs={12} md={8} lg={6} xl={4} className="offset-0 offset-md-2 offset-lg-3 offset-xl-4">
											<Button onClick={ e => navigate("/")} className="w-100 my-3 mt-lg-5">Home</Button>
										</Col>
									</Row>
								</React.Fragment>
							}
						</Col>
					</Row>
				</div>
			</Container>
}