import { Form, Formik } from "formik";
import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { useSessionStore } from "../../Stores/SessionStore";
import * as yup from 'yup';
import { useEffect } from "react";
import { UsernameInput, getUsernameValidation } from "../Core/UsernameInput";

export default function ForgotPasswordModal(props) {
	const [requestingReset, setRequestingReset] = useState(false);
	const [error, setError] = useState(null);
	const [requestSent, setRequestSent] = useState(false);

	const store = useSessionStore();
	const validationSchema =
		yup.object({
			EmailPreference: yup.mixed().required("Communication Preference is required."),
			Email: getUsernameValidation("EmailPreference"),
		}).required();

	async function handleFormSubmitClicked(values) {
		setError(null);
		setRequestingReset(true);
		setRequestSent(false);
		
		var result = await store.CustomersService.RequestPasswordReset(values.Email,values.EmailPreference);
		setRequestingReset(false);

		if (result && result.Success) {
			setRequestSent(true);
		} else {
			//setError("An error occurred requesting your password be reset, please contact support.");
			setRequestSent(true);
		}
	}

	useEffect(() => {
		setError(null);
		setRequestingReset(false);
		setRequestSent(false);

	}, [props.show]);


	return <Modal size="lg" show={props.show}>
		<Formik validationSchema={validationSchema} initialValues={{ Email: "", EmailPreference: "Phone" }} onSubmit={handleFormSubmitClicked}>
			{({ values }) => (
				<Form>
					<Modal.Header>
						<h3>
							Reset Your Password
						</h3>
					</Modal.Header>
					<Modal.Body>
						{
							requestSent === false &&
							<React.Fragment>
								<p>Please enter the email address or cellphone number associated with your account. <br />We'll send an email/SMS containing a link for you to reset your password.</p>
								<UsernameInput busy={ requestingReset } preferenceField="EmailPreference" usernameField="Email" />
								{
									error &&
									<small className="text-danger">{error}</small>
								}
							</React.Fragment>
						}
						{
							requestSent && 
							<p>If the provided {values.EmailPreference === "Email" ? "email address" : "cellphone number "} is registered with us, you should receieve an {values.EmailPreference === "Email" ? "email" : "sms"} containing a link to reset your password. <br />
								{values.EmailPreference === "Email" ? "Check your spam folder if you don't receieve an email within the hour.": ""} </p>
						}

					</Modal.Body>
					<Modal.Footer>
						{
							requestSent === false &&
							<React.Fragment>
								<Button type="submit" disabled={requestingReset}>OK</Button>
								<Button disabled={requestingReset} onClick={props.onClose}>Cancel</Button>
							</React.Fragment>
						}
						{
							requestSent &&
							<React.Fragment>
								<Button onClick={props.onClose}>Close</Button>
							</React.Fragment>
						}
					</Modal.Footer>
				</Form>
			)}
		</Formik>
	</Modal>
}