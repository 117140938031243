import { Field } from "formik";
import React from "react";
import { Col, Form, Row } from "react-bootstrap";

export default function NewPasswordFields(props) {
	return 	<React.Fragment>
				<Form.Group as={Row} className="mb-3">
					<Form.Label column sm={4}>New Password</Form.Label>
					<Col sm={8}>
						<Field isInvalid={props.errors.NewPassword && props.touched.NewPassword } as={Form.Control} name="NewPassword" type="password"></Field>
						<small className="text-danger">{ props.touched.NewPassword && props.errors.NewPassword }</small>
					</Col>
				</Form.Group>

				<Form.Group as={Row} className="mb-3">
					<Form.Label column sm={4}>Confirm New Password</Form.Label>
					<Col sm={8}>
						<Field isInvalid={props.errors.ConfirmNewPassword && props.touched.ConfirmNewPassword }  as={Form.Control} name="ConfirmNewPassword" type="password"></Field>
						<small className="text-danger">{ props.touched.ConfirmNewPassword && props.errors.ConfirmNewPassword }</small>
					</Col>
				</Form.Group>
			</React.Fragment>
}