import APIBase from "./APIBase";

/**
* @typedef RadiusAddress
* @property {string} Username
* @property {string} Address
* @property {string} City
* @property {string} Zip
* @property {string} Country
* @property {string} State
*/

/**
 * @typedef CustomerRadiusDetails
 * @property {string} ServiceName
 * @property {number} ServiceId
 * @property {string} IPAddress
 * @property {string} AccountType
 * @property {string} ExpiryDate
 * @property {number} CurrentUsage
 * @property {number} Price,
 * @property {RadiusAddress} Address
 */

/**
 * @typedef Customer
 * @property {string} Id
 * @property {string} FirstName
 * @property {string} Surname
 * @property {string} Email
 * @property {string} CellphoneNumber
 * @property {string} LanguagePreference
 * @property {string} EmailPreference
 * @property {string} IDNumber
 * @property {string} RadiusUsername
 * @property {boolean} SetOwnPassword
 * @property { any[] } SuccessfulRecharges
 */

/**
 * @typedef CustomerDetails
 * @property {Customer} Customer
 * @property {CustomerRadiusDetails} RadiusDetails
 */

/**
 * @typedef Document
 * @property {string} Id
 * @property {string} FileName
 * @property {string} Content
 */

/**
 * @typedef CustomerRegistration
 * @property {Customer} Customer
 * @property {Document} IDDocument
 * @property {Document} ProofOfAddress
 */

/**
 * @typedef UpdateProfile
 * @property {string} Email
 * @property {string} CellphoneNumber
 * @property {string} CurrentPassword
 * @property {string} NewPassword
 * @property {string} NewPasswordConfirm
 * @property {string} CommunicationPreference
 * @property {string} Language
 */

/**
 * @typedef CustomersService
 * @type {CustomersService}
 */
export class CustomersService extends APIBase {
/**
     * @param {string} apiUrl
     * @param { import("../Stores/SessionStore").SessionStore } sessionStore
     **/
	constructor(apiUrl, sessionStore) {
		super(apiUrl + "Customers", sessionStore);
	}

	/**
     * @returns { import("./APIBase").EnumerableRequestResult<RadiusAddress> }
     */
     async SearchAddress(searchString) {
        return this.Get(`SearchAddress?searchString=${encodeURIComponent(searchString)}`);
    }

	/**
     * @returns { import("./APIBase").RequestResult<CustomerDetails> }
     */
     async GetCustomerDetails() {
        return this.Get(`GetCustomerDetails`);
    }

	/**
     * @returns { import("./APIBase").RequestResult<boolean> }
     */
     async RegisterCustomer(customerRegistration) {
        return this.Post(`RegisterCustomer`, customerRegistration);
    }

    /**
     * @returns { import("./APIBase").RequestResult<boolean> }
     */
    async ResubmitCustomerDocument(customerId,customerRegistration) {
        return this.Post(`ResubmitCustomerDocument?customerId=${encodeURIComponent(customerId)}`, customerRegistration);
    }

	/**
     * @returns { import("./APIBase").RequestResult<boolean> }
     */
     async Login(username, password, preference) {
        return this.Post(`Login`, { Username: username, Password: password, Preference: preference });
    }

	/**
	 * @param { UpdateProfile } profile
     * @returns { import("./APIBase").RequestResult<boolean> }
     */
     async UpdateProfile(profile) {
        return this.Post(`UpdateProfile`, profile);
    }

	/**
     * @returns { import("./APIBase").RequestResult<boolean> }
     */
     async SetOwnPassword(newPassword, newPasswordConfirm) {
        return this.Post(`SetOwnPassword?newPassword=${encodeURIComponent(newPassword)}&newPasswordConfirm=${encodeURIComponent(newPasswordConfirm)}`, null);
    }

	/**
     * @returns { import("./APIBase").RequestResult<boolean> }
     */
     async ResetPassword(resetRequestId, newPassword, newPasswordConfirm) {
        return this.Post(`ResetPassword?resetRequestId=${resetRequestId}&newPassword=${encodeURIComponent(newPassword)}&newPasswordConfirm=${encodeURIComponent(newPasswordConfirm)}`, null);
    }

	/**
     * @returns { import("./APIBase").RequestResult<boolean> }
     */
     async RequestPasswordReset(emailAddress,preference) {
        return this.Post(`RequestPasswordReset?emailAddress=${encodeURIComponent(emailAddress)}&preference=${encodeURIComponent(preference)}`, null);
    }

	/**
	 * @param { string } customerId
     * @returns { import("./APIBase").RequestResult<boolean> }
     */
     async CancelSubscription(customerId) {
        return this.Post(`CancelSubscription?customerId=${customerId}`);
    }
    
	/**
	 * @param { SendRouterNeededEmail } customer
     * @returns { import("./APIBase").RequestResult<boolean> }
     */
    async SendRouterNeededEmail(addressId, customer) {
        return this.Post(`SendRouterNeededEmail?addressId=${addressId}`, customer);
    }
}