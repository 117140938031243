import React from 'react';
import ReactDOM from 'react-dom';
import App from './Components/App';

import "./CSS/bootstrap.min.css";
import { SessionStoreProvider } from './Stores/SessionStore';

fetch("./config.json").then(async (response) =>
{
    if(response.ok)
    {
        var config = await response.json();

        ReactDOM.render(
            <React.StrictMode>
                <SessionStoreProvider config={ config }>
                    <App></App>
                </SessionStoreProvider>
            </React.StrictMode>,

            document.getElementById('root')
        );
    }
});