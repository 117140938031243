import { faAngleLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { Row, Col, Modal, Button } from "react-bootstrap";
import { Formik, Form } from "formik";
import * as yup from 'yup';
import logo from "../../../../Images/logo.png";
import PayAtLogo from "../../../../Images/PayAtLogo.png";
import BoxerLogo from "../../../../Images/BoxerLogo.jpg";
import AckermansLogo from "../../../../Images/AckermansLogo.jpg";
import ShopriteLogo from "../../../../Images/ShopriteLogo.jpg";
import FlashLogo from "../../../../Images/FlashLogo.png";
import PEPLogo from "../../../../Images/PEPLogo.png";
import SparLogo from "../../../../Images/Sparlogo.jpg";
import CheckersLogo from "../../../../Images/CheckersLogo.jpg";
import RhinoLogo from "../../../../Images/RhinoLogo.png";
import { useSessionStore } from "../../../../Stores/SessionStore";
import CashPaymentAlertModal from "./CashPaymentAlertModal";
import RoundedImage from "../../../Core/RoundedImage";
import { UsernameInput, getUsernameValidation } from "../../../Core/UsernameInput";

export default function CashPaymentModal({ show, onClose, cancelTransaction, contactPreference, contactDetails, productDetails }) {
	const store = useSessionStore();

	const [showAlertModal, setShowAlertModal] = useState(false);
	const [alertData, setAlertData] = useState({ title:"", message:"", code:"", contactPreference:"", icon:"" });
	const [sendingCode, setsendingCode] = useState(false);

	const validationSchema =
		yup.object({
			ContactPreference: yup.mixed().required("Contact Preference is required."),
			ContactDetails: getUsernameValidation("ContactPreference"),
	}).required();

	function handleCancel() {
		onClose(false);
		cancelTransaction(false);
	}

	async function handleSendCode(values) {
		setsendingCode(true);

		let results = await store.RechargesService.InitiateCashPayment(values.ContactDetails, values.ContactPreference, productDetails.Id);

		setShowAlertModal(true);
		setsendingCode(false);

		if(results && results.Success){
			setAlertData({ title: "Success", code: results.Data, contactPreference: values.ContactDetails});
		}

		onClose(false);
	}

    return(
        <React.Fragment>
            <Modal show={ show } size="lg">
                <Modal.Header style={{ backgroundImage: "linear-gradient(130deg, #009845 0%, #FFE200 100%)"}}>
                    <Row className="d-flex align-items-center justify-content-center h-100">
                        <Col className="text-left text-white d-flex align-items-center">
                            <div onClick={() => onClose(false)} className="d-flex flex-row">
                                <div className="px-2"><FontAwesomeIcon icon={faAngleLeft} size="sm" transform="grow-3"></FontAwesomeIcon></div>
                                <div>Back</div>
                            </div>
                        </Col>
                        <Col className="text-center p-0">
                            <img src={logo} className="mw-100 mh-100 img-responsive img-fluid p-0" alt="Logo" />
                        </Col>
                        <Col className="d-flex flex-column align-items-end text-right font-weight-bold" >
                            <p style={{ fontSize: 12, fontStyle:'italic'}}>Secured and powered by</p>
                            <img src={PayAtLogo} className="mw-100 mh-100" style={{ height: 50, width: 90 }} alt="PayAt Logo" />
                        </Col>
                    </Row>
                </Modal.Header>
                <Modal.Body>
                    <div className="p-2 text-center font-weight-bold">
                        { productDetails.Category } at R{ productDetails.Price } for { productDetails.Name }
                    </div>
                    <div className="p-4 text-center">
                        <p className="m-0">Your Pay@ code will be sent to you:</p><br />
						<Formik validationSchema={ validationSchema } initialValues={{ ContactPreference: contactPreference, ContactDetails: contactDetails || "" }} onSubmit={ handleSendCode }>
							<Form>
								<UsernameInput busy={ sendingCode } preferenceField="ContactPreference" usernameField="ContactDetails" />
								<Button type="submit" className="w-100 col-md-10 col-lg-7 p-n1 mt-3" style={{backgroundImage: "linear-gradient(130deg, #009845 0%, #FFE200 100%)"}} disabled={ sendingCode }>{ sendingCode ? "Sending... " : "Send"}</Button><br />
								<Button type="button" className="w-100 col-md-10 col-lg-7 p-n1 mt-3" style={{backgroundImage: "linear-gradient(150deg, #FFEF00 0%, #FF7800 100%)"}} onClick={ handleCancel } href="/#/Portal/Recharge">Cancel Transaction</Button>
							</Form>
						</Formik>
					</div>
					<div className="p-4 text-center">
						Pay@ payments are for payments made directly at one of these participating stores:
					</div>
					<Row className="justify-content-center">
						<RoundedImage logo={ BoxerLogo } />
						<RoundedImage logo={ AckermansLogo } />
						<RoundedImage logo={ ShopriteLogo } />
						<RoundedImage logo={ FlashLogo } />
					</Row>
					<Row className="justify-content-center">
						<RoundedImage logo={ PEPLogo } />
						<RoundedImage logo={ SparLogo } />
						<RoundedImage logo={ CheckersLogo } />
						<RoundedImage logo={ RhinoLogo } />
					</Row>
				</Modal.Body>
				<Modal.Footer style={{ backgroundColor: "#FF5C0D" }}>
					<Row className="text-white text-center">
						Please take your Pay@ SMS or Email to a participating store and complete your payment over the counter.
					</Row>
				</Modal.Footer>
			</Modal>
			<CashPaymentAlertModal show={ showAlertModal } closeModal={ setShowAlertModal } title={ alertData.title } icon={ alertData.icon } code={ alertData.code } contactPreference={ alertData.contactPreference } message={ alertData.message } />
		</React.Fragment>
	)
}