import APIBase from "./APIBase";

/**
 * @typedef RechargesService
 * @type {RechargesService}
 */
export class RechargesService extends APIBase {
	/**
     * @param {string} apiUrl
     * @param { import("../Stores/SessionStore").SessionStore } sessionStore
     **/
	constructor(apiUrl, sessionStore) {
		super(apiUrl + "Recharges", sessionStore);
	}

	/**
	 * @param { string } contactDetails The phone number or email address that will receive the payment code
	 * @param { string } contactPreference Whether the value above is an email address or phone number
     * @param { string } productId The Id of the product the cash payment is for
     * @returns { import("./APIBase").RequestResult<string> }
     */
     async InitiateCashPayment(contactDetails, contactPreference, productId) {
        return this.Post(`InitiateCashPayment?contactDetails=${encodeURIComponent(contactDetails)}&contactPreference=${encodeURIComponent(contactPreference)}&productId=${encodeURIComponent(productId)}`);
    }
}