import APIBase from "./APIBase";

/**
 * @typedef Product
 * @property {string} Id
 * @property {number} Price
 * @property {string} Name
 * @property {string} Category
 * @property {number} Period
 */


/**
 * @typedef ProductsService
 * @type {ProductsService}
 */
export class ProductsService extends APIBase {
/**
	 * @param {string} apiUrl
	 * @param { import("../Stores/SessionStore").SessionStore } sessionStore
	 **/
	constructor(apiUrl, sessionStore) {
		super(apiUrl + "Products", sessionStore);
	}

	/**
	 * @returns { import("./APIBase").EnumerableRequestResult<Product> }
	 */
	async GetProducts() {
		return this.Get(`GetProducts`);
	}
}