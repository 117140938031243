import React from "react";
import { Col, Form, Row } from "react-bootstrap";

export default function Address(props) {

	function getAddress(address) {
		if(address) {
			return `${address.ERF}, ${address.StreetAddress}, ${address.MDUName ? address.MDUName + ", " : ""}${address.MDUBlock ? address.MDUBlock + ", " : ""}${address.MDUUnitNumber ? address.MDUUnitNumber + ", " : ""}${address.Township}`;
		}
	}

	return 	<React.Fragment>
					<Form.Group as={Row} className="mb-3">
						<Form.Label column sm={4}>Address</Form.Label>
						<Col sm={8}>
							<input readOnly className="form-control" value={ props && props.address ? getAddress(props.address) : "" }></input>
						</Col>
					</Form.Group>
					<Form.Group as={Row} className="mb-3">
						<Form.Label column sm={4}>City</Form.Label>
						<Col sm={8}><input readOnly className="form-control" value={ props?.address?.Township }></input></Col>
					</Form.Group>
					<Form.Group as={Row} className="mb-3">
						<Form.Label column sm={4}>Zip</Form.Label>
						<Col sm={8}><input readOnly className="form-control" value={ props?.address?.Zip }></input></Col>
					</Form.Group>
					<Form.Group as={Row} className="mb-3">
						<Form.Label column sm={4}>Country</Form.Label>
						<Col sm={8}><input readOnly className="form-control" value={ props?.address?.Country }></input></Col>
					</Form.Group>
				</React.Fragment>
}