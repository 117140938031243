import { DateTime } from "luxon";
import { makeAutoObservable, runInAction } from "mobx";
import { createContext, useContext } from "react";
import { CustomersService } from "../Services/CustomersService";
import { ProductsService } from "../Services/ProductsService";
import { RechargesService } from "../Services/RechargesService";
import { PaymentRequestsService } from "../Services/PaymentRequestsService";
/**
 * @typedef Session
 * @type {object}
 * @property {string} BearerToken - The JWT Token used to authenticate the client.
 * @property { import('../Services/CustomersService').Customer } Customer - The logged in customer
 */

/**
 * @typedef Config
 * @type {object}
 * @property {string} apiUrl - The URL to the application's backend API.
 * @property {string} payfastTest - Whether payfast is in test mode or not.
 * @property {string} payfastMerchantId - The payfast merchant id.
 * @property {string} payfastMerchantKey - The payfast merchant key.
 * @property {string} payfastPassphrase - The payfast passphrase.
 * @property {string} payfastNotifyUrl - the Url used by payfast to notify us of payments
 * @property {string} payfastCancelUrl
 * @property {string} payfastSuccessUrl
 */

/** @type {SessionStore} */
var SessionStoreInstance = null;

const SessionStoreContext = createContext(null);

/**
 * @typedef SessionStore
 * @type {SessionStore}
*/
export class SessionStore {
	/**@type {Session} */ Session = null;
	LoggingIn = false;
	RetrievingCustomerDetails = false;

	/**@type { import("../Services/CustomersService").CustomerDetails } */
	CustomerDetails = null;
	SubscriptionStatus = null;

	/**@param {Config} config */
	constructor(config) {
		//attempt to initialise the session from local storage
		this.GetStoredSession();
		this.Config = config;

		//initialise services
		this.CustomersService = new CustomersService(config.apiUrl, this);
		this.ProductsService = new ProductsService(config.apiUrl, this);
		this.RechargesService = new RechargesService(config.apiUrl, this);
		this.PaymentRequestsService = new PaymentRequestsService(config.apiUrl, this);

		makeAutoObservable(this);
	}

	async LoadCustomerDetails() {
		if(this.RetrievingCustomerDetails === false) {
			this.CustomerDetails = null;
			this.RetrievingCustomerDetails = true;
			var result = await this.CustomersService.GetCustomerDetails();
			
			runInAction(() => { this.RetrievingCustomerDetails = false; });
			
			if(result && result.Success) {
				runInAction(() => { this.CustomerDetails = result.Data; });
				return true;
			}

			return false;
		}
	}

	SetSubscriptionStatusActive(statusActive) {
		this.SubscriptionStatus = statusActive;
	}

	/**
	 * Returns whether or not the current customer has and active billed service
	 * @returns {boolean} true if the customer is not on the default service and their service has not expired, and they have at least one recharge, false if otherwise
	 */
	CustomerHasActiveService() {
		if(this.CustomerDetails && this.CustomerDetails.RadiusDetails && this.CustomerDetails.Customer.SuccessfulRecharges[0]) {
			if(this.CustomerDetails.RadiusDetails.ServiceId !== 0) {
				var expiryDate = DateTime.fromISO(this.CustomerDetails.RadiusDetails.ExpiryDate);
				
				if(!expiryDate.invalidReason) {
					let expiryStart = expiryDate.startOf("day");
					let nowStart = DateTime.now().startOf("day");
					if(expiryStart >= nowStart) {
						return true;
					}
				}
			}
		}

		return false;
	}

	GetStoredSession() {
		var session = localStorage.getItem("Session");

		if(session) {
			this.Session = JSON.parse(session);
		} else {
			this.Session = null;
		}
	}

	get UserIsLoggedIn () {
		return this.Session !== null;
	}

	async SetLoggingIn(loggingIn) {
		runInAction(() => { this.LoggingIn = loggingIn; });
	}

	async Login(username, password, preference) {
		this.SetLoggingIn(true);
		var result = await this.CustomersService.Login(username, password, preference);
		this.SetLoggingIn(false);

		if(result.Success) {
			localStorage.setItem("Session", JSON.stringify(result.Data));
			runInAction(() => { this.Session = result.Data; });
		}

		return result;
	}

	Logout () {
		localStorage.clear();
		this.SubscriptionStatus = null;
		this.Session = null;
	}
}

export function SessionStoreProvider(props) {
	if(SessionStoreInstance === null) {
		SessionStoreInstance = new SessionStore(props.config);
	}

	return <SessionStoreContext.Provider value={ SessionStoreInstance }>{ props.children }</SessionStoreContext.Provider>
}

/**
 * @returns {SessionStore}
 */
export function useSessionStore() {
	const context = useContext(SessionStoreContext);

	return context;
}