import React from "react";
import { Col } from "react-bootstrap";

export default function RoundedImage(props) {
    return (
        <Col xs="4" md="2" className="p-3 m-3 shadow" style={{ borderRadius: '10px' }}>
            <Col class="d-flex justify-content-center align-items-center">
                <img src={ props.logo } className="img-fluid"></img>
            </Col>
        </Col>
    )
}